import React from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col
} from "reactstrap";
import { EventTracker } from '../services/Analytics';

export default function Faq() {
  const [openId, setOpenId] = React.useState(null);
  const toggleCollapse = (id) => {
    setOpenId(openId === id ? null : id);
  };

  const openCollapse = ({ id, faq }) => {
    toggleCollapse(id);
    EventTracker({
      category: 'FAQ',
      action: faq.q,
      label: faq.a
    });
  };

  const questions = [{
    q: 'What are the hours of operation?',
    a: 'We\'re open every Saturday. Hours of Operation are from 9:30pm to 2:00am.'
  },
  {
    q: 'What is the age requirement for entry?',
    a: 'Club X is a 18+ event.'
  },
  {
    q: 'What is Club X Dress Code?',
    a: 'Dress to impress. No hoodies, sports attire, hats or inappropriate/ offensive wording on clothing.'
  },
  {
    q: 'Where do I park?',
    a: 'There is free parking all along Harrison Street. Additionally, there is a paid parking lot on the East side of our building. Please refrain from keeping any valuables inside your vehicle.'
  },
  {
    q: 'What is the cover charge?',
    a: 'Admission at the door is $20 unless stated on the event flyer.'
  },
  {
    "q": "How do I purchase tickets for an event?",
    "a": "You can purchase tickets directly on our website by selecting the event you want to attend, choosing your ticket type, and completing the checkout process. We accept major credit cards, Apple Pay, Google Pay, and other digital wallets for a quick and seamless checkout experience."
  },
  {
    "q": "What if I don’t receive my confirmation email?",
    "a": "If you haven’t received your confirmation email, you can show your ID to our staff at the door, and your ticket can be looked up. Alternatively, contact us through our contact page, and we’ll resend your ticket to your email."
  },
  {
    "q": "Can I get a refund if I can't attend the event?",
    "a": "We do not offer refunds. However, you can contact us before the event if you have any concerns or questions about your purchase."
  },
  {
    "q": "Do I need to print my ticket?",
    "a": "No, you can present your ticket electronically on your mobile device for entry. However, if you prefer a physical ticket, you may print it at home."
  },
  {
    q: 'Do you offer Guest List?',
    a: 'Yes, We have promoters all over the Bay Area. Reach out to them to be added on to a guest list.'
  },
  {
    q: 'Do you guys have a coat-check?',
    a: 'Yes. We have a coat check located past our front door to the left.'
  },
  {
    q: 'Refund Policy',
    a: 'Our tickets are a final sale. No refunds, exchanges, or cancellations.'
  },
  {
    q: 'How do I book a Private Event',
    a: 'Click this link for more information: ',
    linkName: '715harrison.com',
    link: 'https://715harrisoneventspace.com'
  }];

  return (
    <>
      <div className="cd-section" id="accordion">
        {/* ********* Accordion 1 ********* */}
        <div className="accordion-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title mb-4 mt-5 text-white">Frequently Asked Question</h2>
                <div className="section-space" />
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="12">
                <div className="accordion">
                  {
                    questions.map((data, index) => (
                      <Card key={index}>
                        <CardHeader>
                          <h5 className="mb-0">
                            <Button
                              className="w-100 text-left"
                              color="link"
                              aria-expanded={openId === index}
                              onClick={() => openCollapse({
                                id: index,
                                faq: data
                              })}
                            >
                              {data.q}{" "}
                              <i className="tim-icons icon-minimal-down float-right" />
                            </Button>
                          </h5>
                        </CardHeader>
                        <Collapse isOpen={openId === index}>
                          <CardBody>
                            <span style={{fontSize: '1.2em', fontWeight: 'bold'}}>
                            {data.a} {data?.link ? <a target="_BLANK" rel="noreferrer" href={data.link}>{data?.linkName}</a> : ''}
                            </span>
                            
                          </CardBody>
                        </Collapse>
                      </Card>
                    ))
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}
