import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import NavBar from '../../components/NavBar'
import PhotoGallery from '../../components/PhotoGallery'
import { getServer } from '../../services/server'
import { toast } from 'react-toastify'

export default function Album() {
  const { slug } = useParams();
  const [pageData, setPageData] = useState();
  const [nothingToSee, setNothingToSee] = useState(false);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await getServer(`/galleries/byslug/${slug}`);
      setPageData(response);
      if (response?.items.length > 0) {
        setPhotos(response.items);
      } else {
        setPhotos( response?.images );
      } 
    }
    fetchData();
  }, [slug]);

  if (nothingToSee) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <NavBar />
      {pageData ? (
        <>
          {pageData?.items && (
            <PhotoGallery
              title={pageData?.name}
              date={pageData?.gallery_date}
              // details={data.details}
              images={photos}
              data={pageData}
            />
          )}
        </>
      ) : null}

      <Footer />
    </>
  )
}