import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/css';
import 'swiper/css/autoplay';

function EventSlideHeader({events, border=false}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bannerIndex, setBannerIndex] = useState(0);
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const styles = {
    bannerContainer: {
      position: 'relative',
      display: windowWidth >= 768 ? 'block' : 'none',
      width: '100%',
      height: '400px',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      margin: '0 auto',
      textAlign: 'center',
      zIndex: 0,
      borderRadius: windowWidth <= 768 ? '0' : '45px',
    },
    bannerImage: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
      height: '100%',
      zIndex: 100,
      position: 'relative',
    },
    bannerOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      opacity: 1,
      margin: '0 auto',
      textAlign: 'center',
      filter: 'blur(20px) brightness(1)',
      zIndex: 1,
    },
  };

return (
    <div style={styles.bannerContainer} className={`banner-container ${border ? 'border-2 border-gray-600' : ''}`}>
      <div key={bannerIndex} className='animation-fade' style={{ backgroundImage: `url('${events[bannerIndex]?.promo?.banner}')`, ...styles.bannerOverlay }}></div>
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        allowSlideNext={events.length > 1 ? true : false}
        allowSlidePrev={events.length > 1 ? true : false}
        pagination={false}
        centeredSlides={true}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        onActiveIndexChange={(index) => setBannerIndex(index.realIndex)}
        style={{ width: '100%', height: '100%', overflow: 'hidden' }} // Ensure full width and correct overflow
        className="swiper-container"
      >
        {events.map((data, index) => (
          <SwiperSlide key={index} style={{ width: '100%', height: '100%' }}>
            <div style={{ backgroundImage: `url('${data?.promo?.banner}')`, ...styles.bannerImage }}></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export default EventSlideHeader;