import React from 'react'

function Ribbon({title}) {
  return (
    <div className="ribbon">
      <span>{title}</span>
    </div>
  )
}

export default Ribbon