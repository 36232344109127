import React, { useContext, useEffect, useState } from 'react'
import Footer from '../components/Footer'
import MainHeader from '../components/headers/MainHeader'
import NavBar from '../components/NavBar'
import RentalSection from '../components/RentalSection'
import Subscribe from '../components/Subscribe'
import Analytics from '../services/Analytics'
import { getServer } from '../services/server'
import { CompanyContext } from '../contexts/CompanyContaxt'
import PromoModal from '../components/PromoModal'

export default function LandingPage() {
  const company = useContext(CompanyContext);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    Analytics()
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    async function getPromoBanners() {
      const response = await getServer(`/events/getPromoBanners/${company?._id}`);
      if (response.length > 0) {
        setModal(true)
        setModalData(response);
      };
    }
    if (company?._id) {
      getPromoBanners();
    }
  }, [company?._id]);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <NavBar />
      <MainHeader />
      <RentalSection />
      <Subscribe />
      <Footer />
      <PromoModal modal={modal} toggle={toggle} modalData={modalData} />
    </>
  )
}
