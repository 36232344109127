import {getServer} from './server';

async function getCompany () {
    let address = window.location.hostname;

    return await getServer('/companies/getByAddress/' + address).then(res => {
        return res || {}
    });
}

export default getCompany;