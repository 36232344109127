import React from 'react'
import { Slide } from 'react-slideshow-image';
import ReactPlayer from 'react-player/lazy';
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GiMorgueFeet } from "react-icons/gi";
import { BiDrink } from "react-icons/bi";
import Analytics from '../../services/Analytics';
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import ContactMap from '../../components/ContactMap';
import { Link } from 'react-router-dom';
import moment from 'moment';

// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};


function Rental() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    Analytics()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);

  const facts = [
    'Consisting of three bars',
    'Two dance floors',
    'Rane 68 DJ Mixer',
    '2 Technic 1200 M3S',
    '2 EAW booth monitors',
    '2 Pioneer DVJ-X1',
    '2 Shure wireless mics',
    '1 headset/lapel mic',
    '24 Elation Design Spot 300 Pros',
    '4 High end Technobeams',
    '2 strobe lights',
    '10 led par/bar',
    '2 fog machines',
    '1 video projector'
  ];

  const images = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  ];

  const fakeNumbers = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]

  return (
    <>
      <NavBar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header header-filter">
          <div
            className="page-header-image"
            data-parallax={true}
            style={{
              backgroundImage:
                "url(https://mirrorcorp.s3.us-west-2.amazonaws.com/images/adPhotos/25.jpg)"
            }}
          />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 style={{ fontSize: '10vw' }} className="title x4">715 HARRISON</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section about-description">
          <img
            alt="..."
            className="path path4"
            src={require("../../assets/img/path4.png")}
          />
          <Container>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-primary">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("../../assets/img/feature-blob/primary.png")}
                    />
                    <GiMorgueFeet size={'3em'} />
                  </div>
                  <h4 className="info-title">15,000 Square Feet</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("../../assets/img/feature-blob/success.png")}
                    />
                    {/* <i className="tim-icons icon-atom" /> */}
                    <AiOutlineUsergroupAdd size={'3em'} />
                  </div>
                  <h4 className="info-title">1,200 Person Capacity</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("../../assets/img/feature-blob/warning.png")}
                    />
                    <BiDrink size={'3em'} />
                  </div>
                  <h4 className="info-title">Two Rooms | Dance Hall | Three Bars</h4>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="">
              <Col className="ml-auto mr-auto text-center" md="8">
                <h4 className="description">
                  <b>Our passion is being a part of the San Francisco cultural scene.</b><br />
                  We embody San Francisco’s quirkiness of juxtapositions. We are organized people who understand what it means to live a little.
                </h4>
                <br />
                <h4 className="description">
                  <b>We eat crispy tacos under decadent chandeliers.</b><br />
                  We have a history of facilitating memorable events for all walks of life. There is no client too large for us or too small. We are good people and have a special place in our hearts for non-profits and students.
                </h4>
                <br />
                <h4 className="description">
                  Event planning, venue rental, private parties, corporate events, holiday parties, fitness events, wine tastings, launch parties, state-of-the-art lighting & sound, catering, coordination, creation, production, planning & logistics. All with an open mind.
                </h4>
              </Col>
              <Col style={{marginTop:60}} className="text-center" md="12">
                <a href="https://715harrisoneventspace.com" target='_BLANK' rel="noreferrer">
                  <Button className="ml-1" color="primary" type="button">
                    Rental Request
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Rental