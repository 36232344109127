import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import Ticket from './Ticket';

export default function TicketSwiper({ events, company }) {

  return (
    <>
      <Swiper
        modules={[Navigation]}
        allowSlideNext={events.length > 1 ? true : false}
        allowSlidePrev={events.length > 1 ? true : false}
        pagination={true}
        navigation={events.length > 1 ? true : false}
        centeredSlides={true}
        slidesPerView={1}
        spaceBetween={30}
        loop={false}
        style={{ width: '100%', height: '100%', overflow: 'hidden' }} // Ensure full width and correct overflow
        className="swiper-container"
      >
        {events.length > 0 && events.map((data, index) => (
          <SwiperSlide key={index} style={{ width: '100%', height: '100%' }}>
            <Ticket ticket={data} company={company} bgColor={index % 2 === 0 ? '#1f2251' : '#1f2252'} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
