function round(num) {
  return Math.round(num * 100) / 100;
}

function buildTicketFees(tickets, stewieServiceCharge = 3.5, stripeProcessingFee = 0.029, stripeProcessingFixedFee = 0.3) {

  const ticketCharges = tickets.reduce((sum, ticket) => sum + ticket.price, 0);
  if (ticketCharges === 0) return freeTicket();
  const extraTicketFees = tickets.reduce((sum, ticket) => sum + ticket.extraFee || 0, 0);
  const serviceCharges = tickets.length * stewieServiceCharge + extraTicketFees;
  const unroundedTotal = (ticketCharges + serviceCharges + stripeProcessingFixedFee) / (1 - stripeProcessingFee);
  const total = round(unroundedTotal);
  const processingFees = round(unroundedTotal - ticketCharges - serviceCharges);

  return {
    total,
    breakdown: {
      tickets: ticketCharges,
      serviceCharges,
      extraTicketFees,
      processingFees,
    },
    tickets: tickets.map(ticket => {
      if (!ticket.extraFee) ticket.extraFee = 0;
      return ({
        ...ticket,
        serviceCharge: stewieServiceCharge,
        totalServiceCharge: stewieServiceCharge + ticket.extraFee || 0,
        processingFee: (((ticket.price + (stewieServiceCharge + ticket.extraFee) + (stripeProcessingFixedFee / tickets.length)) / (1 - stripeProcessingFee)) - ticket.price - (stewieServiceCharge + ticket.extraFee)),
        total: ((ticket.price + (stewieServiceCharge + ticket.extraFee) + (stripeProcessingFixedFee / tickets.length)) / (1 - stripeProcessingFee))
      })
    })
  }
}

function freeTicket () {
  return {
    total: 0,
    breakdown: {
      tickets: 0,
      serviceCharges: 0,
      extraTicketFees: 0,
      processingFees: 0,
    },
    tickets: []
  }
}

export default buildTicketFees;