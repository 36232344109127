import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { getServer } from '../services/server';
import { CompanyContext } from '../contexts/CompanyContaxt';

export default function SiteRoutes({ footer }) {
  const company = useContext(CompanyContext);
  // eslint-disable-next-line no-unused-vars
  const [routeType, setRouteType] = useState('nav-link');
  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    async function getData() {
      if (company._id) {
        try {
          const response = await getServer(`/organizations/getByCompany/${company._id}`);
          let list = response.map(org => {
            return {
              name: org.name,
              slug: org.slug,
              _id: org._id
            }
          });
          
          setGalleries(list);
        } catch (err) {
          console.log(err);
        }
      }
    }
    getData();
  }, [company?._id]);

  return (
    <>
      <li className={"" + routeType}>
        <NavLink
          className={routeType}
          to="/"
        >
          Home
        </NavLink>
      </li>

      <li className={"" + routeType}>
        <NavLink
          className={routeType}
          to="/events"
        >
          Event Calendar
        </NavLink>
      </li>

      <li className={"" + routeType}>
        <NavLink
          className={routeType}
          to="/special_events/special_events"
        >
          Special Events
        </NavLink>
      </li>
      {!footer && (
        <li className={"" + routeType}>
          <NavDropdown
            title="Gallery"
            menuVariant="dark"
          >
            {galleries && galleries.length > 0 && galleries.map((gallery, key) => (
              <NavDropdown.Item
                key={key}
                as={Link}
                to={`/gallery/${gallery.slug}`}
              >
                {gallery.name}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </li>
      )}
      <li className={"" + routeType}>
        <a
          className={routeType}
          target='_blank'
          href='https://715harrisoneventspace.com'
          rel="noreferrer"
        >
          Private Events
        </a>
      </li>

      <li className={"" + routeType}>
        <NavLink
          className={routeType}
          to="/partnership"
        >
          Partnership
        </NavLink>
      </li>

      <li className={"" + routeType}>
        <NavLink
          className={routeType}
          to="/faq"
        >
          FAQ
        </NavLink>
      </li>

      <li className={"" + routeType}>
        <NavLink
          className={routeType}
          to="/contact-us"
        >
          Contact Us
        </NavLink>
      </li>

      {/* {authenticated ? (
        <>
          <li className={"" + routeType}>
            <NavLink
              className={routeType}
              to="/contact-us"
            >
              Dashboard
            </NavLink>
          </li>
          <li className={"" + routeType}>
            <NavLink
              className={routeType}
              to="/login"
            >
              Logout
            </NavLink>
          </li>
        </>
      ) : (
        <li className={"" + routeType}>
          <NavLink
            className={routeType}
            to="/login"
          >
            Login
          </NavLink>
        </li>
      )} */}
    </>
  )
}
