import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import "react-image-lightbox/style.css";

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

import lgShare from 'lightgallery/plugins/share';
import 'lightgallery/css/lg-share.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import moment from 'moment';

const PhotoGallery = ({ title, details, images, date, data }) => {
  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="mx-auto margin-bottom-50 margin-top-70 text-center" md="12">
            <h2 style={{ textTransform: 'uppercase', fontSize: 48, fontFamily: 'mainFont' }} className="display-3 margin-top-30 text-white">{title}</h2>
            <h4 style={{ fontFamily: 'clubx', fontSize: 24, }} className="display-3 margin-top-30 text-white">{moment(date).format('dddd, MMM D YYYY')}</h4>
            <p className="lead">
              {details}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='margin-bottom-30'>
            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom, lgShare]}
              share={{
                facebook: true,
                twitter: true,
                instagram: true
              }}
            >
              {images.length > 0 ? (
                <>
                  {images.map((item, key) => (
                    <a className="image-container" key={key} href={data?.hasWatermark ? item?.image_watermarked_url : item?.image_url || item}>
                      <img alt="Club X" src={data?.hasWatermark ? item?.image_watermarked_url : item?.image_url || item} className="img-responsive" />
                    </a>
                  ))}
                </>
              ) : null}
            </LightGallery>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PhotoGallery