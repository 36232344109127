import React, { useEffect, useState } from 'react';

function EventHeader({image, border=false}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    bannerContainer: {
      position: 'relative',
      display: windowWidth >= 768 ? 'block' : 'none',
      width: '100%',
      height: '400px',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      margin: '0 auto',
      textAlign: 'center',
      zIndex: 0,
      borderRadius: windowWidth <= 768 ? '0' : '45px',
    },
    bannerImage: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
      height: '100%',
      zIndex: 100,
      position: 'relative',
    },
    bannerOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      opacity: 1,
      margin: '0 auto',
      textAlign: 'center',
      filter: 'blur(23px) brightness(1)',
      zIndex: 1,
    },
  };

return (
    <div style={styles.bannerContainer} className={`banner-container ${border ? 'border-2 border-gray-600' : ''}`}>
      <div style={{ backgroundImage: `url('${image}')`, ...styles.bannerOverlay }}></div>
      <div style={{ backgroundImage: `url('${image}')`, ...styles.bannerImage }}></div>
    </div>
  )
}
export default EventHeader;