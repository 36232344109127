import React from 'react'
import { Col, Row, Button } from 'reactstrap'
import Logo from '../Logo';
import { Link } from 'react-router-dom';
export default function MainHeader(props) {
  // custom previous button for the slick component
  
  const VideoBackground = () => {
    const videoEl = React.useRef(null);
    const attemptPlay = () => {
      videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
          console.error("Error attempting to play", error);
        });
    };
    React.useEffect(() => {
      attemptPlay();
    }, []);
    return (
      <>
        <video autoPlay muted loop playsInline id="myVideo" ref={videoEl}>
          {/* <source src="https://sfclubs.s3.us-west-1.amazonaws.com/banners/zombieMove.mov" type="video/mp4" /> */}
          <source src="https://sfclubs.s3.us-west-1.amazonaws.com/videos/clubxTrailer.mp4" type="video/mp4" />
        </video>
      </>
    )
  }
  const EventsSlider = () => {
    return (
      <div className="content-center">
        <Row>
          <Col
            className="ml-auto mr-auto"
            md="4"
            style={{
              // marginTop: "15%"
            }}
          >
            <Logo size={250} />
            {/* <div className='three-logo'>
              <LogoThree />
            </div> */}

            {/* <h1 className="title text-white">SF Clubs Event Calendar</h1> */}
            <h1 className="title text-white">Biggest Halloween Parties</h1>
            <p className="description">
              Check out our upcoming events. Hope to see you there.
            </p>
            <Button
              color="primary"
              to="/events"
              size="lg"
              tag={Link}
              style={{marginTop: 20}}
            >
              Event Calendar
            </Button>

            {/* <HalloweenButton org="Ritmo Latino" day="Friday Night" logo={'https://sfclubs.s3-us-west-1.amazonaws.com/logos/1681717536948_ritmoLogo.png'} name="Ritmo Latino Halloween - Friday" route="/events/event/ritmo_latino1724790492421" />
            <HalloweenButton org="Club X" day="Saturday Night" logo={'https://sfclubs.s3.us-west-1.amazonaws.com/logos/logoLight.png'} name="Club X Halloween - Saturday" route="/events/event/halloween_20241718378854522" /> */}
          </Col>
          <Col
            className='d-none d-sm-none d-md-none d-lg-block ml-auto mr-auto'
            md="12"
            style={{
              marginRight: 20
            }}
          >
            {/* <Slick {...slickHeader3Settings}>
              {events.map((data, index) => (
                <div key={index} className='padding-8'>
                  {data.isVideo ? (
                    <video autoPlay muted loop playsInline id="VideoFlyerHeader">
                    <source src={data.file} type="video/mp4" />
                  </video>
                  ) : (
                    <img
                    alt="..."
                    className='img-fluid'
                    src={data.file}
                  />
                  )}
                  
                </div>
              ))}
            </Slick> */}
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <div className="header header-3">
      <div className="page-header header-filter custom-page-header">
        <VideoBackground />
        {/* <Row className='content-center'>
          <Col md={12}>
            <LogoThree height={350} />
          </Col>
          <Col md={12}>
            <Button
              color="primary"
              to="/events"
              size="lg"
              tag={Link}
            >
              Event Calendar
            </Button>
          </Col>
        </Row> */}
        <EventsSlider />
      </div>
    </div>
  )
}