import React from 'react'
import Logo from './Logo'
import { Link, NavLink } from "react-router-dom";
import {
    NavbarBrand,
} from "reactstrap";
import SiteRoutes from './SiteRoutes';

export default function Footer() {
    return (
        <footer id="my-footer" className="footer" data-background-color="black">
            <div className="container">
                <NavbarBrand to="/index" tag={Link} id="tooltip6619950104" className='footer-brand'>
                    <Logo size={95} />
                </NavbarBrand>
                <ul className="pull-center">
                    <SiteRoutes footer={true} />
                </ul>
                <ul className="pull-center">
                    <li className={'nav-link'}>
                        <NavLink
                            className={'nav-link'}
                            to="/terms"
                        >
                            Terms of Service
                        </NavLink>
                    </li>
                </ul>
                <ul className="social-buttons pull-right">
                    <li>
                        <a href="https://twitter.com/club_x_sf" target="_blank" className="btn btn-icon btn-link btn-neutral" rel="noreferrer">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/club.x.sf/" target="_blank" className="btn btn-icon btn-neutral btn-link" rel="noreferrer">
                            <i className="fab fa-facebook-square"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/clubxsf" target="_blank" className="btn btn-icon btn-neutral btn-link" rel="noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
