function formatNumber(number) {
  // Convert the input to a number if it's a string
  var num = number ? Number(number) : 0;

  // Check if the input is a valid number
  if (isNaN(num)) {
    return "Invalid number";
  }

  // Use toLocaleString() to format the number with commas
  var formattedNumber = num.toLocaleString();

  return formattedNumber;
}

export default formatNumber