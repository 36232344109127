import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import formatNumber from '../helpers/formatNumber';
import React from 'react';

function PaginationControls({ currentPage, totalPages, onNextPage, onPrevPage }) {
  return (
    <div style={{ width: '100%' }}>

      <Pagination listClassName="justify-content-center">
        <PaginationItem item onClick={onPrevPage} disabled={currentPage === 1}>
          <PaginationLink href="#">
            Previous
          </PaginationLink>
        </PaginationItem>

        <div style={{ margin: '0 10px' }}>Page {formatNumber(currentPage)} of {formatNumber(totalPages)}</div>
        

        <PaginationItem item onClick={onNextPage} disabled={currentPage === totalPages}>
          <PaginationLink href="#">
            Next
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
}

export default PaginationControls;