import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import NavBar from '../components/NavBar'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  
  return (
    <>
      <NavBar />
      <Container>
        <Row style={{ marginTop: 80, marginBottom: 40 }} className="justify-content-md-center terms-page">
          <Col md="10">
            <h1>Terms and Conditions for Event Ticketing</h1>
            <p>Welcome to <a href="http://sfclubs.com">sfclubs.com</a>, operated by City Nights LP. Thank you for your interest in our events. Before you proceed with your ticket purchase, please carefully read the following terms and conditions that govern the ticket sales for events listed on our website (referred to here as "Event").</p>

            <h2>General</h2>
            <p>The terms "you" and "your" refer to the ticket purchaser. "We", "us", "our", and "City Nights LP" refer to the organizers and operators of the event for which you are purchasing tickets.</p>
            <p>By purchasing tickets through sfclubs.com, you agree to these terms, which form a binding legal agreement. If you do not agree with any part of these terms, you should not proceed with your purchase.</p>

            <h2>Ticket Purchase</h2>
            <p>Tickets sold through sfclubs.com grant you a revocable license to attend the event. This license can be revoked by City Nights LP at any time without compensation if you violate any term of this agreement.</p>
            <p>You must provide your first name, phone number, and email address for ticket processing and information.</p>
            <p>Your ticket purchase is final. There are no refunds, exchanges, or cancellations allowed.</p>
            <p>Tickets must be obtained from sfclubs.com or authorized partners. Tickets obtained from unauthorized sources may be invalid, lost, stolen, or counterfeit, and if so, are void.</p>

            <h2>Data and Privacy</h2>
            <p>We collect your personal information for ticket processing and to inform you of event updates or changes.</p>
            <p>Your personal data will not be sold to third parties. We use Google Analytics to track website usage to improve our service. Payment processing is securely handled by Stripe JS; we do not store or have access to your credit card details.</p>

            <h2>No Refunds Policy</h2>
            <p>All sales are final. Once an event has commenced, we will not provide refunds, credits, or exchanges for any reason, including but not limited to event cancellation, postponement, or dissatisfaction with the event.</p>
            <p>If an event is canceled and not rescheduled, we may, at our discretion, provide a refund for the face value of the ticket.</p>

            <h2>Conduct at Events</h2>
            <p>You agree to follow all rules and regulations of the event venue and to behave in a manner that does not disrupt the enjoyment of the event by others.</p>
            <p>We reserve the right to remove any attendee from the event for failing to adhere to venue rules or for engaging in disruptive, illegal, or offensive behavior without any refund or compensation.</p>

            <h2>Limitation of Liability</h2>
            <p>City Nights LP is not responsible for personal damages, losses, or injuries sustained at the event. This includes, but is not limited to, responsible for lost, stolen, or damaged property at the venue.</p>
            <p>You attend the event at your own risk, understanding that certain inherent risks are involved due to the nature of live events.</p>

            <h2>Changes to Terms</h2>
            <p>We reserve the right to change these terms at any time without prior notice. Updated terms will be posted on sfclubs.com. It is your responsibility to review these terms before purchasing any tickets.</p>

            <h2>Governing Law</h2>
            <p>These terms are governed by the laws of the state where the event is held, without regard to its conflict of law provisions.</p>

            <h2>Dispute Resolution</h2>
            <p>In the event of a dispute, an independent third-party arbiter may be consulted to resolve the issue. The decision of the arbiter will be considered final and binding.</p>

            <h2>Contact Us</h2>
            <p>For questions or concerns about these terms, please contact us via our website.</p>

            <p>We hope you enjoy your experience at our events. Get your tickets now at <Link to="/contact-us">sfclubs.com/contct-us</Link>.</p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Terms