import React, { useContext, useEffect, useState } from 'react'
import NavBar from '../../components/NavBar'
import Faq from '../../components/Faq'
import Footer from '../../components/Footer'
import Analytics from '../../services/Analytics'
import { Redirect, useParams } from 'react-router-dom'
import { getServer } from '../../services/server'
import moment from 'moment';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { CompanyContext } from '../../contexts/CompanyContaxt'
import EventSlideHeader from '../../components/headers/EventSlideHeader'

export default function Events() {
  const company = useContext(CompanyContext);

  const { slug } = useParams();
  const [pageData, setPageData] = useState([]);
  const [nothingToSee, setNothingToSee] = useState(false);
  const [eventLink, setEventLink] = useState('');

  function getURLParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  useEffect(() => {
    const link = getURLParameter('aff');
    let newlink;
    if (link) {
      newlink = '?aff=' + link;
      setEventLink(newlink);
    } else {
      newlink = '?aff=Website';
      setEventLink(newlink);
    }
  }, []);

  useEffect(() => {
    Analytics();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (company?._id && slug) {
      async function getData() {
        try {
          const response = await getServer(`/events/getbyslug/${slug}/${company?._id}`);
          if (response.length > 0) {
            setPageData(response);
          } else {
            toast('No Events at this time.', {
              type: 'warning' || 'default',
              theme: 'dark',
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setNothingToSee(true);
          }
        } catch (err) {
          console.log('page error', err);
        }
      }
      getData();
    }
  }, [slug, company?._id]);

  if (nothingToSee) {
    return <Redirect to="/events" />;
  }

  // const EventCard2 = (props) => {
  //   let affLink;
  //   // if  prop  aff is present, add URLSearchParams to the link
  //   if (eventLink) {
  //     affLink = `${props?.slug}${eventLink}`;
  //   }

  //   return (
  //     <div className="col-md-3">
  //       <div className="card profile-card-1">
  //         <img src={props?.promo?.story || props?.promo?.post} alt="profile-sample1" className="background" />
  //         <img src={props?.promo?.story || props?.promo?.story} alt="profile" className="profile" />
  //         <div className="card-content">
  //           <h2>{props?.name}<small>{moment(props.start_date).format('lll')}</small></h2>
  //           <Link to={`/events/event/${eventLink ? affLink : props.slug}`} className="btn btn-primary btn-round">View Event</Link>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const EventCard2 = (props) => {
    let affLink;
    // if prop aff is present, add URLSearchParams to the link
    if (props.eventLink) {
      affLink = `${props.slug}${props.eventLink}`;
    }
  
    return (
      <div className="event-card2 col-md-3">
        <Link to={`/events/event/${props.eventLink ? affLink : props.slug}`} className="card profile-card-1">
          <img src={props?.promo?.story || props?.promo?.post} alt="profile-sample1" className="background" />
          <img src={props?.promo?.story || props?.promo?.post} alt="profile" className="profile" />
          <div className="card-content">
            <h2>{props?.name}<small>{moment(props.start_date).format('lll')}</small></h2>
            <Link to={`/events/event/${props.eventLink ? affLink : props.slug}`} className="btn btn-primary btn-round event-button2 events-page-button">View Event</Link>
          </div>
        </Link>
      </div>
    )
  }
  

  return (
    <>
      <NavBar />
      <EventSlideHeader events={pageData} />
      <div className="main main-raised">
        <div className="container">
          <div className="row">
            {pageData && Array.isArray(pageData) && pageData.length > 0 ? pageData.map((data, index) => (
              <EventCard2 {...data} key={index} />
            )) : null}
          </div>
        </div>
      </div>
      <Faq />
      <Footer />
    </>
  )
}
